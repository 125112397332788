import clsx from 'clsx';
import React from 'react';
import * as styles from './GoToSection.module.css';
import { Link } from 'gatsby';
import GlobalWrapper from '../GlobalWrapper/GlobalWrapper';

export default function GoToSection({ className, wrapperClassName, content, children=null, showTextShadow=null }) {
  return (
    <section className={ clsx(styles.component, className, showTextShadow && styles.textShadow) } >
      <GlobalWrapper className={ wrapperClassName } showBorderLeft={ true }>
        <div className={ clsx(styles.header, content.headerClassName) }>
          <h2 className={ styles.title }>{ content.title }</h2>
        </div>
        <section className={ styles.wrapper }>
          <div className={ styles.content }>{ content.text }</div>
          <div className={ styles.actions }>
            <div className={ styles.cta }>
              <Link to={ content.cta.to } title={ content.cta.title } >{ content.cta.text }</Link>
            </div>
          </div>
        </section>
        {
          children
        }
      </GlobalWrapper>
    </section>
  );
}