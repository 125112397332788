import React from 'react';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';
import clsx from 'clsx';
import * as styles from '../styles/pages/home.module.css';

import Layout from '../components/Layout';
import GoToSection from '../components/GoToSection/GoToSection';
import References from '../components/References/References';
import bottleChildImg from '../images/bottle-childHiFive.png';
import bottleDancingImg from '../images/bottle-dancing.png';
import bottleManImg from '../images/bottle-manWithBoquet.png';
import ExitLink from '../components/ExitLink/ExitLink';
import Footnotes from '../components/Footnotes/Footnotes';

import { GtagHead } from '../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>LOQTORZI® (toripalimab-tpzi)</title>
      <meta name='description'
        content='LOQTORZI® is the first and only treatment with a successful registrational trial in recurrent or metastatic nasopharyngeal cancer approved by the FDA. Please see Indications and Important Safety Information.'/>
    </>
  )
}

export default function Home() {
  const goToSections = [
    {
      title: <>Significantly improved <br className='tablet:hidden' /> PFS AND OS<sup>1#</sup></>,
      text: <div>LOQTORZI is the first and only <br className='tablet:hidden'/>treatment with a successful <br className='desktop:hidden'/>registrational <br className='hidden desktop:block'/>trial in R/M NPC that <br className='tablet:hidden'/>has led to FDA approval.<sup>3</sup>

        <Footnotes className={'mt-[1rem] !text-white tablet:flex tablet:justify-center'} items={[
              {
                bullet: <sup>#</sup>,
                text: <span>Patients were treated for up to 6 cycles, followed by LOQTORZI alone.<sup>1</sup></span>
              }
              ]}
            />
      </div>,
      cta: {
        to: '/loqtorzi-efficacy-first-line-treatment/',
        text: <span>Explore the data</span>,
        title: 'Explore how LOQTORZI improves the prediction of progression-free survival (PFS)'
      },
      className: '',
      headerClassName: 'relative z-[1] !pt-[2.9rem]'
    },
    {
      title: <>LOQTORZI AT A GLANCE</>,
      text: <div>Swipe through key <br className='tablet:hidden'/>information and highlights <br className='tablet:hidden'/>about LOQTORZI <br className='hidden tablet:block'/>
      to help <br className='tablet:hidden'/>you support your patients.</div>,
      cta: {
        to: '/loqtorzi-at-a-glance/',
        text: <span>Take a glance</span>,
        title: 'Learn about LOQTORZI® to help you support your patients'
      },
      className: '',
      headerClassName: 'relative z-[1]'
    },
    {
      title: <>PATIENT SUPPORT PROGRAM</>,
      text: <div>LOQTORZI Solutions™ provides <br className='tablet:hidden'/>your eligible patients with resources <br className='hidden tablet:block'/>
        <br className='tablet:hidden'/>to help them access financial <br className='tablet:hidden'/>support and treatment.</div>,
      cta: {
        to: '/loqtorzi-solutions/',
        text: <span>See our solutions</span>,
        title: 'LOQTORZI provides resources to help your patients access financial support and treatment'
      },
      className: '',
      wrapperClassName: 'relative',
      children:
        <img className={ styles.bottle2 } width='204' height='430' src={ bottleManImg } alt="Bottle man with boquet" />
    },
    {
      title: <>STAY IN THE KNOW</>,
      text: <div>Register to receive <br className='tablet:hidden'/>the latest updates <br className='tablet:hidden'/>and <br className='hidden tablet:block'/>
      announcements <br className='tablet:hidden'/>about LOQTORZI.</div>,
      cta: {
        to: '/get-updates/',
        text: <span>Get updates</span>,
        title: 'Register to receive the latest updates about LOQTORZI'
      },
      className: clsx(styles.circle4, ''),
      children: <div className='pl-[1rem]'>
        <img className={ styles.bottle3Big } width='267' height='510' src={ bottleChildImg } alt="Bottle child hi five" />

        <Footnotes className={'relative z-[8] mt-[24.4rem] small:mt-[5.4rem] !text-white'} items={[
          {
            bullet: '',
            text: <span>BIRC=blinded independent review committee; CI=confidence interval; HR=hazard ratio; NE=not estimable; NPC=nasopharyngeal carcinoma; PD-L1=programmed cell death ligand 1; RECIST=Response Evaluation Criteria in Solid Tumors; R/M&nbsp;NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</span>
          }
          ]}
        />

        <References className='relative z-[8] ml-0 pt-[5.2rem] !pr-[.8rem] !pl-0 !text-white'
        items={[
          <span>
            LOQTORZI (toripalimab-tpzi) Prescribing Information. Redwood City, CA: Coherus BioSciences, Inc.
          </span>,
          <span>Mai H-Q, Chen Q-Y, Chen D, et al. Toripalimab or placebo plus chemotherapy as first-line treatment in advanced nasopharyngeal carcinoma: a multicenter randomized phase 3 trial. <em>Nat Med.</em> 2021;27(9):1536-1543. doi:10.1038/s41591-021-01444-0</span>,
          <>
            ClinicalTrials.gov. 55 studies found for: recurrent, metastatic | nasopharyngeal carcinoma. Accessed September 28, 2023. <ExitLink className='break-all' url={'https://clinicaltrials.gov/ct2/results?term=Recurrent%2C+Metastatic&cond=Nasopharyngeal+Carcinoma&age_v=&gndr=&type=&rslt=&phase=2&Search=Apply'}>https://clinicaltrials.gov/ct2/results?term=Recurrent%2C+Metastatic&cond=Nasopharyngeal+Carcinoma&age_v=&gndr=&type=&rslt=&phase=2&Search=Apply</ExitLink>
          </>
        ]}/>
      </div>,
      showTextShadow: true
    }
  ];

  return (
    <Layout className={styles.homeLayout}>
      <section>
        <div className={styles.circle1}/>
        <div className={styles.circle2}/>
        <div className={styles.circle3}/>
        <GlobalWrapper className={'relative'} showBorderLeft={ true }>
          <img className={ styles.bottle1 } width='195' height='388' src={ bottleDancingImg } alt="Bottle couple dancing" />
          <div className={ styles.subtitle }>
            <div className='pt-[1.6rem] font-BarlowCondensedBold text-[2.3rem] text-gold-600 tablet:text-[2.6rem]'>
              Approved irrespective<br className='tablet:hidden'/> of PD-L1 status.<sup>2</sup>
            </div>
          </div>
        </GlobalWrapper>
      </section>
      <div className='overflow-hidden'>
        {
          goToSections.map((item, index) => {
            return(
              <GoToSection className={ item.className } wrapperClassName={ item.wrapperClassName } key={ index } content={ item } showTextShadow={ item.showTextShadow }>
                { item.children }
              </GoToSection>
            );
          })
        }
      </div>
    </Layout>
  );
}
