// extracted by mini-css-extract-plugin
export var bottle1 = "home-module--bottle1--b3ecb";
export var bottle2 = "home-module--bottle2--cc440";
export var bottle3 = "home-module--bottle3--a2709";
export var bottle3Big = "home-module--bottle3Big--5b2b5";
export var circle1 = "home-module--circle1--4d23d";
export var circle2 = "home-module--circle2--ef283";
export var circle3 = "home-module--circle3--7c643";
export var circle4 = "home-module--circle4--8d732";
export var homeLayout = "home-module--homeLayout--2a0d2";
export var subtitle = "home-module--subtitle--ec21f";